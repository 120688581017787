import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectToperacioncrm', method: 'toperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectEmpleado', method: 'empleado.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectGrupoEmpleado', method: 'grupoEmpleado.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },

  // TODO: esto no me gusta, ya tiene muchos parámetros
  async selectOperacionCRM (
    Vue,
    filter,
    search,
    sorter,
    page,
    abierta,
    asignada,
    campoFK,
    identificador,
    pageSize,
    idempleado,
    esTecnico,
    esComercial,
    esVigilante
  ) {
    const apiFilter = Vue.$online.operacioncrm.buildApiFilterVisualizacion(esTecnico, esComercial, esVigilante)
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
    if (campoFK) {
      apiFilter.addExact(campoFK, identificador)
    }
    if (abierta) {
      apiFilter.addExact(filter.abierta.field, abierta)
    }
    if (asignada) {
      apiFilter.addExact(filter.asignada.field, idempleado)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.operacioncrm.searchFilter(search))
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    if (filter.abierta.value !== null ) {
      apiFilter.addExact(filter.abierta.field, filter.abierta.value)
    }
    if (filter.asignada.value) {
      apiFilter.addExact(filter.asignada.field, idempleado)
    }
    if (filter.informada.value) {
      apiFilter.addExact(filter.informada.field, idempleado)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.toperacioncrm.value && filter.toperacioncrm.value.length > 0) {
      apiFilter.addIn(filter.toperacioncrm.field, filter.toperacioncrm.value)
    }
    // Hago esto así porque en la API estos filtros son subconsultas, y no funciona bien con arrays de valores
    if (filter.responsable.value && filter.responsable.value.length > 0) {
      const apiFilterResponsable = new APIFilter()
      for (const value of filter.responsable.value) {
        apiFilterResponsable
          .setOperator(API_FILTER_OPERATORS.OR)
          .addExact(filter.responsable.field, value)
      }
      apiFilter.addNestedFilter(apiFilterResponsable)
    }
    if (filter.informador.value && filter.informador.value.length > 0) {
      apiFilter.addIn(filter.informador.field, filter.informador.value)
    }
    if (filter.grupoEmpleado.value && filter.grupoEmpleado.value.length > 0) {
      apiFilter.addIn(filter.grupoEmpleado.field, filter.grupoEmpleado.value)
    }
    const resp = await Vue.$api.call('operacioncrm.selectList', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectOperacionCRMRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idoperacioncrm', pks)
    const resp = await Vue.$api.call('operacioncrm.selectList', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
